export const COURIERS = {
  blueexpress: 1,
  shippify: 2,
  chilexpress: 3,
  recibelo: 16,
  urbano: 17
}

export const BILL_STATUS = [
  {value: 1, name: 'En revision' },
  { value: 2, name: 'Aprobada para facturar' },
  { value: 3, name: 'Facturado' },
  { value: 4, name: 'Enviada para pago' },
  { value: 5, name: 'Pagada' }
]

export const BILLING_STATUS_INDEX = {
  'under review': 'En revision',
  approved: 'Aprobada para facturar',
  billed: 'Facturado',
  sent: 'Enviada para pago',
  paid: 'Pagada'
}

export const PARCEL_STATUS = [
  {value: 1, name: 'Pendientes' },
  { value: 2, name: 'Modificados' }
]

export const PARCEL_STATUS_INDEX = {
  pending: 'Pendientes',
  modified: 'Modificados'
}

export const BANK_ACCOUNT_TYPE = [
  { id: 1, name: 'cuenta corriente' },
  { id: 2, name: 'cuenta vista' },
  { id: 3, name: 'cuenta de ahorro' }
]

export const DOWNLOAD_EXCEL_RETURN_OPTIONS = {
  SUCCESFUL: {
    status: 200,
    data: { body: 'Búsqueda exitosa' }
  },
  NO_DATA: {
    status: 404,
    data: { body: 'No hay facturas para este mes' }
  },
  ERROR: {
    status: 500,
    data: { body: 'Ha ocurrido un problema al buscar los datos' }
  }
}

export const BILL_FILE_TYPES = [
  { label: 'Factura', type: 'invoice', fileType: 'PDF', multiple: false },
  { label: 'Recibo', type: 'receipt', fileType: 'PDF', multiple: false },
  { label: 'Nota de crédito', type: 'creditNote', fileType: 'PDF', multiple: true }
]

export const VIEW_BAR = [
  { name: 'couriers', label: 'Facturas Courier' },
  { name: 'clientes', label: 'Facturas Clientes' },
  { name: 'pedidos', label: 'Pedidos' }
]

export const PARSE_VIEW = {
  couriers: 'courier',
  clientes: 'holding'
}
