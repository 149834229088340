import React from 'react'

const CustomToggle = ({ notification, handleNotificationToggle }) => (
  <label className="relative inline-flex w-48 cursor-pointer items-center">
    <input
      id={`${notification.id}_toggle`}
      type="checkbox"
      value=""
      className="peer sr-only"
      onChange={() => handleNotificationToggle(notification.id, notification.active)}
      checked={notification.active}
    />
    <div className='peer h-5 w-10 rounded-full bg-gray-200 after:absolute after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[""] peer-checked:bg-pinflag peer-checked:after:translate-x-full peer-focus:outline-none rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800' />
  </label>
)

export default CustomToggle

