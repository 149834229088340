import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { BILL_FILE_TYPES, BILL_STATUS, BILLING_STATUS_INDEX } from '../../../constants/billing'
import { getBills, removeFile, updateBills, uploadFile } from '../../../helpers/requests/billing'
import Loader from '../../shared/Loader'
import { backArrow, BILLING_ICONS, errorIcon, infoIcon } from '../../../constants/icons'
import FormButton from '../../shared/FormButton'
import ConfirmModal from '../../shared/Modal/ConfirmModal'

import FileUpload from './FileUpload'

const EditBill = () => {
  const [bill, setBill] = useState({})
  const [formValues, setFormValues] = useState({})
  const [apiResponse, setApiResponse] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceFile, setInvoiceFile] = useState(null)
  const [receiptFile, setReceiptFile] = useState(null)
  const [creditNoteFile, setCreditNoteFile] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [currentFile, setCurrentFile] = useState({})

  const invoiceRef = useRef(null)
  const receiptRef = useRef(null)
  const creditNoteRef = useRef(null)

  const fileRefs = {
    invoice: invoiceRef,
    receipt: receiptRef,
    creditNote: creditNoteRef
  }

  const fileStates = {
    invoice: invoiceFile,
    receipt: receiptFile,
    creditNote: creditNoteFile
  }

  const history = useHistory()
  const { id: holdingId, billId } = useParams()

  const initialzeData = useCallback((data) => {
    setBill(data.bills[0])
  }, [])

  useEffect(() => {
    if (shouldFetch) {
      getBills(`?holdingId=${holdingId}&billId=${billId}`)
        .then(initialzeData)
        .finally(() => setShouldFetch(false))
    }
  }, [shouldFetch, billId, initialzeData, holdingId])

  useEffect(() => {
    setFormValues({ ...bill })
  }, [bill])

  const billingFileField = {
    invoice: setInvoiceFile,
    receipt: setReceiptFile,
    credit_note: setCreditNoteFile
  }

  const handleGoBack = () => {
    history.push('/facturas')
  }

  const handleFiles = (files, name) => {
    const formData = new FormData()
    for (let i = 0; i < files.length; i += 1) {
      formData.append('file', files[i])
    }
    const setFileFunction = billingFileField[name]
    setFileFunction(formData)
  }

  const handleChange = (event) => {
    const { name, value, files } = event.target

    if (files) {
      handleFiles(files, name)
    } else {
      setFormValues({
        ...formValues,
        [name]: value
      })
    }
  }

  const handleSubmitModal = async () => {
    setIsLoading(true)
    const { data, status } = await updateBills({ bill: formValues })
    setApiResponse({ ...data, status })
    setShouldFetch(true)
    setIsLoading(false)
  }

  const removeLocalFile = (id) => {
    if (id.includes('receipt')) {
      setReceiptFile(null)
      receiptRef.current.value = null
    } else if (id.includes('invoice')) {
      setInvoiceFile(null)
      invoiceRef.current.value = null
    } else if (id.includes('credit')) {
      setCreditNoteFile(null)
      creditNoteRef.current.value = null
    }
  }

  const removeAwsFile = async (file, id) => {
    await removeFile(file.map((obj) => obj.name).join(','))
    let updatedFormValues
    if (id.includes('receipt')) updatedFormValues = { ...formValues, receipt: null }
    if (id.includes('invoice')) updatedFormValues = { ...formValues, invoice: null }
    if (id.includes('credit')) updatedFormValues = { ...formValues, creditNote: null }
    setFormValues(updatedFormValues)
    return updateBills({ bill: updatedFormValues })
  }

  const handleUploadFile = async (type) => {
    setIsLoading(true)
    const fileUrls = {}
    const { data: fileResponse } = await uploadFile(fileStates[type])
    fileUrls[type] = fileResponse.filesData
    const { data, status } = await updateBills({
      bill: { ...formValues, ...fileUrls }
    })
    removeLocalFile(type)
    setIsLoading(false)
    setApiResponse({ ...data, status })
    setShouldFetch(true)
  }

  const handleFileDelete = async () => {
    setIsLoading(true)
    const { data, status } = await removeAwsFile(currentFile.file, currentFile.id)
    setApiResponse({ ...data, status })
    setShouldFetch(true)
    setIsLoading(false)
    setShowModal(false)
  }

  const handleFileDeleteModal = async (file, id, type) => {
    if (type === 'local') {
      removeLocalFile(id)
      return
    }
    setShowModal(true)
    setCurrentFile({ id, file, type })
  }

  const handleButtonClick = (type) => () => {
    if (type === 'invoice') invoiceRef.current.click()
    if (type === 'receipt') receiptRef.current.click()
  }

  const renderIcon = () => {
    if (isLoading) {
      return (
        <div className="flex w-1/2 items-center justify-center">
          <Loader />
        </div>
      )
    }

    const icon = apiResponse?.status === 200 ? BILLING_ICONS.paid : errorIcon
    const altText = apiResponse?.status === 200 ? 'success' : 'error'
    if (apiResponse) {
      return (
        <div className="flex w-1/2 flex-col items-center justify-center">
          <img src={icon} className="mx-auto h-16 w-16" alt={altText} />
          {apiResponse?.status === 200
            ? 'Cambios guardados exitosamente'
            : `Ha ocurrido un error al guardar los datos: ${apiResponse?.body}`}
        </div>
      )
    }
    return (
      <div className="flex w-1/2 items-center justify-center">
        <img src={infoIcon} className="h-auto w-16 opacity-20" alt="Info" />
      </div>
    )
  }

  const compareEditedData = () => JSON.stringify(formValues) === JSON.stringify(bill)
  const restoreData = () => setFormValues({ ...bill })

  const statusSelector = (name, setter, options) => (
    <>
      <div className="">Estado</div>
      <select
        className="h-12 w-full cursor-pointer rounded-xl border-2 border-dark-grey bg-white px-2.5 text-sm text-gray-900 focus:border-blue-300 focus:ring-blue-300"
        onChange={(e) => setter({ target: { value: e.target.value, name } })}
        value={BILLING_STATUS_INDEX[formValues.status]}
        id={`${name}-select`}
      >
        {options?.map((option) => (
          <option key={option.name} value={option.name}>
            {option.name}
          </option>
        ))}
      </select>
    </>
  )

  return (
    <div className="relative h-fit ">
      <ConfirmModal
        show={showModal}
        handleSubmit={handleFileDelete}
        handleClose={() => setShowModal(false)}
        isLoading={isLoading}
        message="Borrar? Esta acción no se puede deshacer"
      />
      <button
        type="button"
        className="mx-10 mt-4 mb-6 flex items-center justify-center rounded-lg px-2 py-1 hover:bg-medium-gray"
        onClick={() => handleGoBack()}
      >
        <img src={backArrow} className="mr-5 h-auto w-5" alt="volver" />
        <div className="text-left text-xl font-semibold">Editar Factura</div>
      </button>
      <div className="mx-20 mb-20 h-fit rounded-[2rem] bg-white p-20">
        <div className="flex w-4/5 flex-col gap-8">
          <div className="flex flex-col">
            Folio
            <input
              id="folio"
              type="text"
              name="invoiceNumber"
              className="h-10 w-1/2 rounded-full border-2 border-dark-grey bg-white px-2"
              value={formValues?.invoiceNumber || ''}
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex w-full gap-2">
            <div className="w-1/2">
              <div>Emisión</div>
              <input
                id="emitModal"
                name="createdAt"
                className="h-10 w-full rounded-full border-2 border-dark-grey bg-white px-2"
                type="date"
                value={formValues?.createdAt || ''}
                onChange={handleChange}
              />
            </div>
            <div className="w-1/2">
              <div>Expiración</div>
              <input
                id="expireDateModal"
                name="expires"
                className="h-10 w-full rounded-full border-2 border-dark-grey bg-white px-2"
                type="date"
                value={formValues?.expires || ''}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex w-full gap-2">
            <div className="w-1/2">
              A cobrar
              <input
                id="pinflagCharge"
                type="number"
                name="pinflagCharge"
                className="h-10 w-full rounded-full border-2 border-dark-grey bg-white px-2"
                value={formValues?.pinflagCharge || 0}
                onChange={handleChange}
              />
            </div>
            <div className="w-1/2">{statusSelector('status', handleChange, BILL_STATUS)}</div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 flex-col gap-8">
              {BILL_FILE_TYPES.map((billType) => (
                <FileUpload
                  key={billType.type}
                  label={billType.label}
                  subText="PDF"
                  inputId={`${billType.type}`}
                  inputRef={fileRefs[billType.type]}
                  file={fileStates[billType.type]}
                  fetchedFiles={bill[billType.type]}
                  handleChange={handleChange}
                  handleButtonClick={handleButtonClick(billType.type)}
                  handleFileDelete={handleFileDeleteModal}
                  handleFileUpload={handleUploadFile}
                  properties={{ multiple: billType.multiple, name: billType.type }}
                />
              ))}
            </div>
            {renderIcon()}
          </div>
        </div>
        <div className="flex items-end justify-end gap-4 p-4">
          {!compareEditedData() && (
            <>
              <FormButton text="Cancelar" onClick={restoreData} styles="bg-white px-4 py-2" />
              <FormButton text="Guardar" onClick={handleSubmitModal} styles="px-4 py-2" />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditBill
