import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import Loader from '../shared/Loader'
import Table from '../shared/Table/Table'
import TableRow from '../shared/Table/TableRow'
import Pagination from '../shared/Pagination'
import ActionsBar from '../shared/Table/ActionsBar'
import { BILLING_STATUS_INDEX, PARCEL_STATUS_INDEX, PARSE_VIEW } from '../../constants/billing'
import { exportIcon } from '../../constants/icons'
import ToolTip from '../shared/ToolTip'
import { handleBillingsDownload } from '../../helpers/OrdersBilling'
import Filters from '../shared/Filters/Filters'
import SearchBarWithFetch from '../shared/Input/SearchBarWithFetch'

const TableSection = ({
  title,
  fetchData,
  TableHeaders,
  filters,
  renderRow,
  type = 'clientes',
  filterOptions = [],
  setShowModal = () => {},
  packagesTypeSelector,
  shouldFetch = true,
  setShouldFetch = () => {}
}) => {
  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [selectedFilters, setSelectedFilters] = useState([])
  const [isFiltered, setIsFiltered] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [initialDate, setInitialDate] = useState(
    moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
  )
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'))
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const initializeData = useCallback(
    (dataValues) => {
      const array = type === 'pedidos' ? dataValues.foundPackages : dataValues.bills
      setData(array)
      setSearchResult(array)
      setDataCount(dataValues.count)
    },
    [type]
  )

  const filtersURI = () => {
    const response =
      Object.keys(selectedData).length > 0 ? encodeURIComponent(JSON.stringify(selectedData)) : ''
    return response
  }

  const billURL = `?type=${
    PARSE_VIEW[type] || 'holding'
  }&page=${page}&billId=${''}&pageSize=${pageSize}&searchParam=${searchBarInput}&startDate=${initialDate}&finalDate=${finalDate}&selectedItems=${filtersURI()}`

  const parcelURL = `?holdingId=&billingId=&page=${
    page || 1
  }&searchParam=${searchBarInput}&pageSize=${pageSize}&selectedItems=${filtersURI()}`

  useEffect(() => setShouldFetch(true), [fetchData, setShouldFetch])

  useEffect(() => {
    const contentURLS = {
      couriers: billURL,
      clientes: billURL,
      pedidos: parcelURL,
      'pedidos perdidos': parcelURL
    }
    if (shouldFetch) {
      setIsLoading(true)
      fetchData(contentURLS[type])
        .then(initializeData)
        .catch(() => setError(true))
        .finally(() => {
          setShouldFetch(false)
          setIsLoading(false)
        })
    }
  }, [
    shouldFetch,
    initializeData,
    fetchData,
    type,
    page,
    pageSize,
    searchBarInput,
    initialDate,
    finalDate,
    selectedData,
    billURL,
    parcelURL,
    setShouldFetch
  ])

  const filteredData = searchResult.filter((item) => {
    const filterToInclude =
      type === 'clientes' || type === 'couriers'
        ? BILLING_STATUS_INDEX[item.status]
        : PARCEL_STATUS_INDEX[item.review]
    return selectedFilters.length > 0 ? selectedFilters.includes(filterToInclude) : true
  })

  if (error) {
    return (
      <div className="mx-10 my-8 flex flex-col items-center justify-center">
        <div>Error loading data</div>
      </div>
    )
  }

  const handleFilterSubmit = () => {
    setIsLoading(true)
    setShouldFetch(true)
    setIsFiltered(true)
    setPage(1)
  }

  const handleFilterClean = () => {
    setSelectedData([])
    setInitialDate(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'))
    setFinalDate(moment().format('YYYY-MM-DD'))
    setIsFiltered(false)
    setSearchResult(data)
    setShouldFetch(true)
    setPage(1)
  }

  const downloadDetail = () => (
    <ToolTip
      backgroundColor="bg-ultra-dark-grey"
      textColor="text-white"
      hoverElement={
        <button
          className="flex h-10 w-10 items-center justify-center rounded-lg border-2 hover:bg-semi-light-grey"
          onClick={() => handleBillingsDownload(filteredData)}
          type="button"
        >
          <img src={exportIcon} alt="exportar" className="my-auto mx-1.5 w-4" />
        </button>
      }
    >
      Descargar Detalle
    </ToolTip>
  )
  const createBills = () => (
    <ToolTip
      backgroundColor="bg-ultra-dark-grey"
      textColor="text-white"
      hoverElement={
        <button
          className="flex h-10 w-36 items-center justify-center rounded-lg border-2 border-dark-grey
               text-sm hover:bg-semi-light-grey"
          onClick={() => setShowModal(true)}
          type="button"
        >
          <img src={exportIcon} alt="exportar" className="my-auto mx-1.5 w-4" />
          Crear factura
        </button>
      }
    >
      Crea facturas
    </ToolTip>
  )

  return (
    <>
      <div className="mx-10 my-8 flex justify-between">
        <div className="text-left text-xl font-semibold">{title}</div>
        <SearchBarWithFetch
          searchbarInput={searchBarInput}
          setSearchbarInput={setSearchBarInput}
          setShouldFetch={setShouldFetch}
          type="fetch"
        />
      </div>
      <div>
        {type === 'pedidos' && <div className="mx-10">{packagesTypeSelector()}</div>}
        <ActionsBar
          statusFilters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          rightChildren={
            <div className="flex gap-1">
              <Filters
                filterOptions={filterOptions}
                selectedCouriers={selectedData}
                setSelectedCouriers={setSelectedData}
                today={moment().format('YYYY-MM-DD')}
                initialDate={initialDate}
                finalDate={finalDate}
                setInitialDate={setInitialDate}
                setFinalDate={setFinalDate}
                handleSubmit={handleFilterSubmit}
                handleClean={handleFilterClean}
                isFiltered={isFiltered}
                type={type}
              />
              {type === 'clientes' && downloadDetail()}
              {type === 'clientes' && createBills()}
            </div>
          }
        />
      </div>
      {isLoading && <Loader />}
      <div className="relative">
        <Table headers={TableHeaders}>
          {!isLoading &&
            filteredData?.map((item) => (
              <TableRow key={item.id} onClick={() => {}} items={renderRow(item)} />
            ))}
        </Table>
      </div>
      <div className="mx-10 flex h-24 place-content-end">
        <Pagination
          page={page}
          setPage={(numb) => {
            setPage(numb)
            setShouldFetch(true)
          }}
          pageSize={pageSize}
          setPageSize={(numb) => {
            setPageSize(numb)
            setShouldFetch(true)
          }}
          itemsCount={dataCount}
        />
      </div>
    </>
  )
}

export default TableSection
